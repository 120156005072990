<template>
  <div class="container">
    <div class="titoli">About</div>

    <p class="lead p-2">
      Sito sviluppato con facilità da Valerio. Ti piacerebbe un sito come
      questo? Impara a fartelo!
    </p>

    <div class="mt-4">
      <h5>Curriculum Vitae 🐊</h5>
      <ul>
        <li>Classe 1982</li>
        <li>Nato a Torino</li>
        <!-- <li>Allievo di violino di Liza</li>         -->
        <li>Varie lauree/master</li>
        <li>Premio Nobel per l'intelligenza nel 2017</li>
      </ul>
    </div>

    <div class="mt-4">
      <h5>Tech stack / info &#x1F989;</h5>
      <ul>
        <li>Vue.JS Framework</li>
        <li>Node.js & Express.js</li>
        <li>HTML/CSS by Bootstrap</li>
        <li>SQLite</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.container {
  max-width: 500px;
}
</style>
